

































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import PopupMenu from '@/components/PopupMenu.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';

@Component({
  name: 'assigned-contact-card',
  components: {PopupMenu, ContactInfoCard, AvatarWithStatus, DialogConfirm}
})
export default class AssignedContactCard extends Vue {
  @Prop({required: false, default: false}) removable?: boolean
  @Prop({required: false}) sectionTitle?: string
  @Prop({
    default: function() {
      return {};
    }
  }) contact!: BusinessContact;

  dialogConfirm: boolean = false

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get photoUrl() {
    return this.contact?.photoUrl?.normal;
  }

  get online() {
    return !!this.contact?.associate?.status?.online;
  }

  get name() {
    return this.contact.name;
  }

  get position() {
    return this.contact.position || this.contact.description || 'Associate';
  }

  get menu() {
    console.log(this.isAdmin, this.removable)
    return [
      {
        title: 'Share',
        index: 0,
        event: 'on-share-contact'
      },
      {
        title: 'Delete',
        index: 1,
        event: 'on-delete-contact',
        alert: true,
        skip: !this.isAdmin || !this.removable
      }
    ];
  }

  get titleConfirm() {
    return `Do you want to remove contact ‘${this.name}’ ?`
  }

  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        this.$emit(menuItem.event, this.contact.id);
        break;
      }
      case 1: {
        this.dialogConfirm = true
        break;
      }
      default:
    }
  }

  onConfirm() {
    this.$emit('on-delete-contact', this.contact.id)
  }
}
