























import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {AssociateAccount, BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import PopupMenu from '@/components/PopupMenu.vue';

@Component({
  name: 'default-user-card',
  components: {PopupMenu, ContactInfoCard, AvatarWithStatus}
})
export default class DefaultUserCard extends Vue {
  @Prop({required: false}) sectionTitle?: string
  @Prop({
    default: function() {
      return {};
    }
  }) associate!: AssociateAccount;

  get photoUrl() {
    return this.associate?.photoUrl?.normal;
  }

  get online() {
    return !!this.associate?.status?.online;
  }

  get name() {
    return this.associate?.fullName;
  }

  get position() {
    return this.associate?.position || this.associate?.description || 'Associate';
  }

  get menu() {
    return [
      {
        title: 'Share',
        index: 0,
        event: 'on-share-associate'
      }
    ];
  }

  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        this.$emit(menuItem.event, this.associate?.id);
        break;
      }
      default:
    }
  }
}
