























import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Getter} from 'vuex-class';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {profileStore} from '@/store/modules/profile';

@Component({
    name: 'dialog-add-contact-to-group',
    components: {BaseDialog, ContactsList}
})
export default class DialogAddContactToGroup extends Vue {
    @Prop() value!: boolean;
    @Prop() title!: string;
    @Prop() subtitle!: string;

    @Getter selectedContact;

    loading: boolean = false;
    selectedContacts: (BusinessContact | any)[] = [];

    get businessId() {
        return applicationStore.businessId;
    }
    get t2bUser() {
        return profileStore.t2bUser;
    }

    get show() {
        return this.value;
    }

    set show(value: boolean) {
        this.$emit('input', value);
    }

    get filterIDs() {
        const ids = this.selectedContact.contacts.map((item) => item.id);
        return [...ids];
    }

    onContactsSelected(contacts: BusinessContact[]) {
        this.selectedContacts = contacts;
    }

    async onAddSelectedContactsToGroup() {
        if (!this.selectedContacts.length) {
            console.log('No contacts were selected');
            return;
        }
        this.loading = true;
        try {
            await directoryStore.addContactToGroup({
                groupContact: this.selectedContact,
                newContacts: this.selectedContacts
            });
            this.show = false;
            console.log('Contacts have been added');
        } catch (err) {
            console.log(`Add Contacts to group. error: ${err}`);
        }
        this.loading = false;
    }
}
