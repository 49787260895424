

















import {Component, Prop} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Getter} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import {phoneFormat} from '@/utils/helpers';

@Component({
  name: 'contact-info-general',
  components: {VcSwitch, ContactInfoCard}
})
export default class ContactInfoGeneral extends mixins(ContainerCalc) {
  @Prop() group!: boolean;

  @Getter selectedContact;

  get contactEmail() {
    return this.selectedContact?.email;
  }

  get contactPhone() {
    return phoneFormat(this.selectedContact?.phoneNumber);
  }

  mounted() {
    this.calcHeight(this.$refs.contactInfoContainer, [], 'max-width: 800px;')
  }
}
