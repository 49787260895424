










































import {Component, Prop, Watch} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Action, Getter} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import DialogAddBackupContact from '@/components/DialogAddBackupContact.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import BackingContactCard from '@/components/BackingContactCard.vue';
import {copyTextToClipboard} from '@/utils/helpers';
import {applicationStore} from '@/store/modules/application';
import PopupMenu from '@/components/PopupMenu.vue';
import AssignedContactCard from '@/components/profile/AssignedContactCard.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import DefaultUserCard from '@/components/directory/DefaultUserCard.vue';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import ContainerCalc from '@/components/mixins/ContainerCalc';

@Component({
  name: 'contact-info-assignment',
  components: {
    DefaultUserCard,
    AssignedContactCard,
    PopupMenu, BackingContactCard, AvatarWithStatus, DialogAddBackupContact, VcSwitch, ContactInfoCard
  }
})
export default class ContactInfoAssignment extends mixins(Notifications, ContainerCalc) {
  @Prop() group!: boolean;

  @Getter selectedContact;
  @Getter backupContacts;

  showAddBackupPersonDialog: boolean = false;

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get active() {
    if (this.group) {
      return !!this.selectedContact.contacts.find(item => item.associate.workingStatus && item.associate.workingStatus.type === 1);
    } else {
      return this.selectedContact.associate && this.selectedContact.associate.workingStatus && this.selectedContact.associate.workingStatus.type === 1;
    }
  }

  get associate() {
    return this.selectedContact?.associate
  }

  get assignedGroups() {
    return directoryStore.selectedContactAssignmentGroups
  }

  @Action shareContactLink;
  @Action deleteBackingContact;

  @Watch('selectedContact')
  onSelectedContactChanged(after, before) {
    if (after?.id !== before?.id) {
      directoryStore.loadAssignedGroupContacts(after?.id)
    }
  }

  onAddBackupPerson() {
    this.showAddBackupPersonDialog = true;
  }

  async onShareContact(contactId: string) {
    try {
      const shortLink = await this.shareContactLink(contactId);
      await copyTextToClipboard(shortLink);
      this.showInfo('Contact link copied to clipboard')
    } catch (err) {
      console.log(`share contact. error: ${err}`);
      this.showIssue('Failed to generate link');
    }
  }

  async onShareAssociate(associateId: string) {
    try {
      const shortLink = await profileStore.shareProfileLink(associateId);
      await copyTextToClipboard(shortLink);
      this.showInfo('User link copied to clipboard')
    } catch (err) {
      console.log(`share contact. error: ${err}`);
      this.showIssue('Failed to generate link');
    }
  }

  async onDeleteBackingContact(contactId: string) {
    try {
      await this.deleteBackingContact({contact: this.selectedContact, backingId: contactId});
    } catch (err) {
      console.log(`onDeleteBackingContact error: ${err}`);
    }
  }

  created() {
    directoryStore.loadAssignedGroupContacts(this.selectedContact?.id)
  }

  mounted() {
    this.calcHeight(this.$refs.contactAssignmentContainer, [], 'max-width: 800px;')
  }
}
