

























































import {Component, Vue} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Getter} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import {phoneFormat} from '@/utils/helpers';

@Component({
  name: 'customer-profile-general',
  components: {VcSwitch, ContactInfoCard}
})
export default class CustomerProfileGeneral extends Vue {

  NA: string = 'N/A';
  ALLOWED: string = 'Allowed';
  HIDDEN: string = 'Hidden';

  @Getter selectedCustomer;

  get permissions(): any {
    return this.selectedCustomer?.permissions;
  }

  get allowedContact(): boolean {
    return !!this.permissions?.contact;
  }

  get allowedPromo(): boolean {
    return !!this.permissions?.promote;
  }

  get allowedViewName(): boolean {
    return !!this.permissions?.viewName;
  }

  get allowedViewEmail(): boolean {
    return !!this.permissions?.viewEmail;
  }

  get allowedViewPhone(): boolean {
    return !!this.permissions?.viewPhone;
  }

  get allowedViewSSN(): boolean {
    return !!this.permissions?.viewSSN;
  }

  get allowedViewAddress(): boolean {
    return !!this.permissions?.viewAddress;
  }

  get allowedViewDOB(): boolean {
    return !!this.permissions?.viewDOB;
  }

  get privateEmail() {
    if (!this.allowedViewEmail) {
      return this.HIDDEN;
    }
    return this.selectedCustomer.email || this.NA;
  }

  get privatePhone() {
    if (!this.allowedViewPhone) {
      return this.HIDDEN;
    }
    return phoneFormat(this.selectedCustomer.phoneNumber) || this.NA;
  }

  get privateSSN() {
    if (!this.allowedViewSSN) {
      return this.HIDDEN;
    }
    return this.selectedCustomer.ssn || this.NA;
  }

  get privateDOB() {
    if (!this.allowedViewDOB) {
      return this.HIDDEN;
    }
    return this.selectedCustomer?.dob || this.NA;
  }

  get address() {
    return this.selectedCustomer.address || {};
  }

  get street() {
    if (!this.allowedViewAddress) {
      return this.HIDDEN;
    }
    return this.address.street || this.NA;
  }

  get city() {
    if (!this.allowedViewAddress) {
      return this.HIDDEN;
    }
    return this.address.city || this.NA;
  }

  get countryState() {
    if (!this.allowedViewAddress) {
      return this.HIDDEN;
    }
    return this.address.countryState || this.NA;
  }

  get zipCode() {
    if (!this.allowedViewAddress) {
      return this.HIDDEN;
    }
    return this.address.zipCode || this.NA;
  }

  get contactPermission() {
    return this.allowedContact ? this.ALLOWED : this.HIDDEN
  }

  get promoPermission() {
    return this.allowedPromo ? this.ALLOWED : this.HIDDEN
  }
}
