


























import {Component, Vue} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Action, Getter} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import DialogAddBackupContact from '@/components/DialogAddBackupContact.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import BackingContactCard from '@/components/BackingContactCard.vue';
import {copyTextToClipboard} from '@/utils/helpers';
import DialogAddContactToGroup from '@/components/DialogAddContactToGroup.vue';
import {applicationStore} from '@/store/modules/application';

@Component({
  name: 'department-info-members',
  components: {
    DialogAddContactToGroup,
    BackingContactCard,
    AvatarWithStatus,
    DialogAddBackupContact,
    VcSwitch,
    ContactInfoCard
  }
})
export default class DepartmentInfoMembers extends Vue {
  @Getter selectedContact;

  showAddContactDialog: boolean = false;

  @Action shareContactLink;
  @Action deleteGroupMember;

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get active() {
    return !!this.selectedContact.contacts.find(item => item.associate.workingStatus && item.associate.workingStatus.type === 1);
  }

  onAddContactToGroup() {
    this.showAddContactDialog = true;
  }

  async onShareMember(contactId: string) {
    try {
      const shortLink = await this.shareContactLink(contactId);
      await copyTextToClipboard(shortLink);
      this.$emit('on-info', 'Copied to clipboard');
    } catch (err: any) {
      console.log(`share contact. error: ${err}`);
      this.$emit('on-error', err.message);
    }
  }

  async onDeleteMember(contactId: string) {
    try {
      await this.deleteGroupMember({contact: this.selectedContact, contactId});
    } catch (err) {
      console.log(`onDeleteMember error: ${err}`);
    }
  }

  async created() {
    console.log('ASSIGNMENT:: created');
  }

  updated() {
    console.log('ASSIGNMENT:: updated');
  }
}
