






























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PopupMenu from '@/components/PopupMenu.vue';
import {profileStore} from '@/store/modules/profile';
import {formatNoteDate} from '@/utils/helpers';
import {chatStore} from '@/store/modules/chat/ChatStore';
import {customersStore} from '@/store/modules/customers/CustomersStore';

@Component({
  name: 'customer-profile-notes',
  components: {PopupMenu},
  filters: {formatNoteDate}
})
export default class CustomerProfileNotes extends Vue {
  @Prop({type: String, required: true}) customerId?: string

  noteText: string = ''
  editNoteId: string = ''

  compareFn = (a, b) => {
    let result = Number(this.isStarred(b)) - Number(this.isStarred(a))
    if (!!result) {
      return result
    }
    result = a.createdDate?.seconds - b.createdDate?.seconds
    return result
  };

  get userId() {
    return profileStore.t2bUser.id
  }

  get customerNotes() {
    return customersStore.notes.sort(this.compareFn)
  }

  get notesLoaded() {
    return customersStore.notesLoaded
  }

  //todo: add type Note
  isStarred(note: any) {
    return !!(note.starred && note.starred[this.userId!]);
  }

  noteTimestamp(note: any) {
    return note.createdDate?.toDate()
  }

  ownerImage(note: any) {
    return note.owner?.photoUrl?.thumbnail || require('@/assets/img/userpic.webp')
  }

  notesMenuItems(note: any) {
    return [
      {
        id: 1,
        title: this.isStarred(note) ? 'Unstar' : 'Star'
      },
      {
        id: 2,
        title: 'Edit',
        skip: note.owner?.id !== this.userId
      },
      {
        id: 3,
        title: 'Delete',
        alert: true,
        skip: note.owner?.id !== this.userId
      }
    ];
  }

  async onCustomerNoteMenuItemSelected(menuItem, note) {
    switch (menuItem.title) {
      case 'Edit':
        this.editNoteId = note.id;
        this.noteText = note.text;
        break;
      case 'Delete':
        await chatStore.deleteCustomerNote({note, customerId: this.customerId});
        break;
      default:
        await chatStore.starCustomerNote({note, customerId: this.customerId});
    }
  }

  async onSaveNote() {
    this.noteText = this.noteText.trim()
    if (!this.noteText.length) {
      this.editNoteId = ''
      return;
    }
    await chatStore.saveCustomerNote({
      noteId: this.editNoteId,
      noteText: this.noteText,
      caseId: null,
      customerId: this.customerId
    });
    this.noteText = ''
    this.editNoteId = ''
  }

  @Watch('notesLoaded')
  onNotesLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    setTimeout(() => {
      const notesLayout: any = this.$refs.notesLayout;
      if (notesLayout) {
        notesLayout.scrollTop = notesLayout.scrollHeight;
        notesLayout.style.scrollBehavior = 'smooth';
      }
      customersStore.setNotesLoaded(false)
    }, 250);
  }
}
