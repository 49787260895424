
import {Component, Prop, Vue} from 'vue-property-decorator';
import {VNode, VNodeData} from "vue";
import {formatMonthDayYear, dateToYMD, isFuture} from '@/utils/helpers';

@Component({name: 'gp-day-switcher'})
export default class DaySwitcher extends Vue {
  @Prop({type: String}) value;

  get day() {
    return this.value;
  }

  set day(value: string) {
    this.$emit('input', value);
  }

  genDay(){
    return this.$createElement('span', {
    }, formatMonthDayYear(this.day))
  }

  genLeft () {
    const self = this;
    return this.$createElement('a', {
      on: {
        click: function() {
          let day: Date = new Date(self.value);
          day.setDate(day.getDate() - 1);
          if (isFuture(day))
            self.day = dateToYMD(day);
        }
      },
      staticClass: 'arrow left',
    })
  }

  genRight () {
    const self = this;
    return this.$createElement('a', {
      on: {
        click: function() {
          let day: Date = new Date(self.value);
          day.setDate(day.getDate() + 1);
          self.day = dateToYMD(day);
        }
      },
      staticClass: 'arrow right',
    })
  }

  render (h): VNode {
    const data: VNodeData = {
      staticClass: 'day-switcher',
    }
    return h('div', data, [
      this.genLeft(),
      this.genDay(),
      this.genRight(),
    ])
  }
};
